<template>
<div class="bg--info">
  <header-block cls=" header__menu--info"/>
  <div class="container container--info">
    <div class="info">
      <h1 class="title__info">
        Любовь к себе
      </h1>
      <p class="info__txt">
        Любовь к себе – самый главный ресурс, внутренняя опора.
        Это фундамент самого главного дома в нашей жизни – фундамент нас самих.
        Как же сделать эту опору надежной, крепкой и устойчивой
        на пути жизненных кризисов и неурядиц?
      </p>
      <div class="info__block">
        <p class="info__txt">
          В основе здоровой любви к себе лежит:
        </p>
        <ul>
          <li>
            <p class="info__txt info__txt--small">
              позитивная собственная самооценка
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              уверенность в себе
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              принятие себя в данном возрасте, внешнем виде
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              гибкие границы
            </p>
          </li>
        </ul>
      </div>
      <p class="info__txt">
        Как ни странно, любить себя безусловно умеют далеко не все.<br>
        В детстве ребенок получает безусловную любовь от родителей по праву рождения.
        Ребенка любят только за то, что он есть. С возрастом у некоторых родителей
        любовь начинает приобретать условные черты. «Вот получишь пятерку – тогда буду
        тебя любить. Получишь двойку – не буду.» Со временем список требований начинает расти,
        и заслуживать любовь становится все сложнее. И в этот момент начинает формироваться
        самокритика, заниженная самооценка внутри себя, самоедство, отвращение к себе,
        постоянное сравнение с другими. Ребенок становится очень удобным для родителя,
        но внутри он постоянно в себе сомневается, «а достоин ли я любви?»<br>
        Вырастая, такой взрослый может любить всех вокруг, причем подчас даже тех,
        кого любить не стоит, кроме себя самого. Им пользуются все, начиная от
        домочадцев и заканчивая коллегами на работе.<br> Внутри идет постоянная
        борьба и самоедство, которое ничего кроме боли и разочарования не несет.
      </p>
      <p class="info__txt">
        Что же делать? Как это поменять?
      </p>
      <div class="info__block">
        <p class="info__txt">
          Начните с внутренней работы над собой:
        </p>
        <ul>
          <li>
            <p class="info__txt info__txt--small">
              перестаньте себя критиковать, винить, стыдить в чем-то
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              начинайте уважать себя, свой выбор работы, увлечений, людей вокруг
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              найдите собственные уникальные качества, таланты, способности
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              поощряйте необычные начинания
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              подбадривайте себя за каждую небольшую победу
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              станьте себе самым большим поклонником
            </p>
          </li>
        </ul>
      </div>
      <div class="info__block">
        <p class="info__txt">
          Внешняя работа над собой может заключаться:
        </p>
        <ul>
          <li>
            <p class="info__txt info__txt--small">
              принятие собственного тела путем контакта с ним: разные скрабы,
              уходы для тела и волос, сеансы массажа: тем самым вы говорите
              себе « Я себя вижу! Я себя чувствую! Я есть!»
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              покупать красивую одежду, делать маникюр, красивый макияж ( или наоборот перестать)
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              разрешить себе получать удовольствие просто от созерцания этого мира
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              никуда не торопится
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              дарить себе приятные подарки просто так, без повода
            </p>
          </li>
          <li>
            <p class="info__txt info__txt--small">
              подбадривайте себя за каждую небольшую победу
            </p>
          </li>
        </ul>
      </div>
      <p class="info__txt">
        «Любовь к себе — бесценное искусство, и постичь его под силу каждому,
        кто раз и навсегда решил изменить свою жизнь к лучшему, обрести радость,
        гармонию, успех и изобилие. Как только вы освоите его азы, приятные и
        порой неожиданные перемены начнут случаться регулярно.»
      </p>
      <router-link class="button" to="/">
        В начало
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
import HeaderBlock from '@/components/Header.vue';
import auth from '@/mixins/auth';

export default {
  mixins: [auth],
  components: {
    HeaderBlock,
  },
  name: 'secret-self',
};
</script>
